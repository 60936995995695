<div class="px-4 py-2 h-[96px] shadow-header bg-forest-dusk">
  <!-- Wrapper for buttons -->
  <div class="container flex h-full max-w-full p-0 mx-auto navbar">
    <!-- Left buttons wrapper -->
    <div class="flex justify-start w-1/2 mb-2 navbar-start">
      <div class="flex mr-1 text-midnight group">
        <div class="flex mt-1 ml-6">
          <p class="text-4xl font-semibold align-text-bottom text-brand-green">
            et
          </p>
          <p class="ml-0 text-4xl font-semibold text-white align-text-bottom">
            admin
          </p>
        </div>
        <svg
          class="ml-2 self-center mt-1"
          width="26"
          height="31"
          viewBox="0 0 26 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0384 5.92174C8.94541 12.3003 3.19851 20.4779 0.0684509 30.3136C0.0684509 30.3136 0.8411 30.1163 1.45583 29.2139C3.9486 25.5311 6.58236 21.6397 6.58236 21.6397C12.882 20.5794 18.8375 16.3665 21.6912 9.66089C19.2549 10.5238 16.2094 10.332 16.2094 10.332C19.5143 9.72293 21.3754 8.3863 21.3754 8.3863C22.3793 5.70743 23.2704 1.72012 26 0.355304C24.7818 0.124074 23.5242 0 22.2327 0C11.472 0 2.56122 8.5273 2.20027 19.3669C7.06174 12.7684 12.7015 8.45398 17.0384 5.92174Z"
            fill="#00E89C"
          />
        </svg>
      </div>

      <div class="flex justify-center gap-4 mt-2 ml-5 text-center text-white">
        <span class="nav-divider"></span>
        <div
          routerLink="/app/users"
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:hover:text-brand-green green-hover"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 48 48"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M39 48H9C8.60218 48 8.22039 47.8417 7.93909 47.5602C7.65778 47.2788 7.5 46.8971 7.5 46.499V31.9851C7.50397 29.4665 8.5056 27.0521 10.2854 25.2712C12.0652 23.4902 14.4777 22.488 16.9948 22.484H31.0049C33.5219 22.488 35.9348 23.4902 37.7146 25.2712C39.4944 27.0521 40.496 29.4665 40.5 31.9851V46.499C40.5 46.8971 40.3419 47.2788 40.0605 47.5602C39.7792 47.8417 39.3978 48 39 48ZM10.5 44.9981H37.5V31.9851C37.496 30.2626 36.8103 28.6123 35.5931 27.3943C34.3759 26.1764 32.7262 25.4899 31.0049 25.4859H16.9948C15.2722 25.4859 13.6201 26.1711 12.4021 27.3899C11.1841 28.6087 10.5 30.2614 10.5 31.9851V44.9981Z"
            />
            <path
              d="M24 18.0113C21.6131 18.0113 19.3238 17.0625 17.636 15.3736C15.9482 13.6847 15 11.3941 15 9.00563C15 6.61719 15.9482 4.32652 17.636 2.63764C19.3238 0.948752 21.6131 0 24 0C26.3869 0 28.6762 0.948752 30.364 2.63764C32.0518 4.32652 33 6.61719 33 9.00563C33 11.3941 32.0518 13.6847 30.364 15.3736C28.6762 17.0625 26.3869 18.0113 24 18.0113ZM24 3.00188C22.4087 3.00188 20.8823 3.63487 19.7571 4.76079C18.6319 5.88671 18 7.41334 18 9.00563C18 10.5979 18.6319 12.1253 19.7571 13.2512C20.8823 14.3771 22.4087 15.0094 24 15.0094C25.5913 15.0094 27.1173 14.3771 28.2426 13.2512C29.3678 12.1253 30 10.5979 30 9.00563C30.004 8.21469 29.8515 7.43114 29.5518 6.69926C29.252 5.96738 28.8107 5.30169 28.2532 4.741C27.6956 4.18032 27.033 3.73567 26.3031 3.43208C25.5732 3.12849 24.7905 2.97182 24 2.97183V3.00188Z"
            />
          </svg>
          <p class="ml-1 text-sm font-semibold">Users</p>
        </div>

        <span class="nav-divider"></span>
        <div
          routerLink="/app/companies"
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:text-brand-green green-hover fill-white"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 48 48"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.5049 22.484H24.4797C23.8228 21.3454 22.8785 20.3994 21.7412 19.7409C20.604 19.0823 19.3139 18.7344 18 18.7317H7.5C5.51088 18.7317 3.60306 19.5229 2.19653 20.9303C0.79001 22.3377 0 24.246 0 26.2364V37.1182C0 37.5163 0.157782 37.8979 0.439087 38.1794C0.720391 38.4609 1.10218 38.6191 1.5 38.6191H15V46.4991C15 46.8971 15.1578 47.2788 15.4391 47.5603C15.7204 47.8418 16.1022 48 16.5 48H46.5C46.8978 48 47.2792 47.8418 47.5605 47.5603C47.8419 47.2788 48 46.8971 48 46.4991V31.9851C47.996 29.4665 46.9944 27.0521 45.2146 25.2712C43.4348 23.4903 41.0219 22.488 38.5049 22.484ZM3 35.6173V26.2364C3 25.0422 3.47408 23.8972 4.31799 23.0528C5.16191 22.2083 6.30653 21.7336 7.5 21.7336H18C18.5979 21.7341 19.1894 21.8541 19.7406 22.0861C20.2918 22.318 20.7913 22.6577 21.2098 23.085C19.393 23.7588 17.8256 24.9722 16.7183 26.5632C15.6109 28.1543 15.0167 30.0463 15.015 31.9851V35.6173H3ZM45 44.9981H18V31.9851C18 30.2614 18.6841 28.6087 19.9021 27.3899C21.1201 26.1711 22.7722 25.4859 24.4948 25.4859H38.4899C40.2138 25.4859 41.8676 26.1701 43.088 27.3885C44.3084 28.6069 44.996 30.2601 45 31.9851V44.9981Z"
            />
            <path
              d="M31.5151 18.0113C33.9021 18.0113 36.1913 17.0625 37.8792 15.3736C39.567 13.6847 40.5151 11.3941 40.5151 9.00563C40.5151 6.61719 39.567 4.32652 37.8792 2.63764C36.1913 0.948752 33.9021 0 31.5151 0C29.1282 0 26.839 0.948752 25.1511 2.63764C23.4633 4.32652 22.5151 6.61719 22.5151 9.00563C22.5151 11.3941 23.4633 13.6847 25.1511 15.3736C26.839 17.0625 29.1282 18.0113 31.5151 18.0113ZM31.5151 3.00188C33.1064 3.00188 34.6325 3.63487 35.7577 4.76079C36.8829 5.88671 37.5151 7.41334 37.5151 9.00563C37.5151 10.5979 36.8829 12.1253 35.7577 13.2512C34.6325 14.3771 33.1064 15.0094 31.5151 15.0094C29.9238 15.0094 28.3974 14.3771 27.2722 13.2512C26.147 12.1253 25.5151 10.5979 25.5151 9.00563C25.5112 8.21469 25.6633 7.43114 25.963 6.69926C26.2628 5.96738 26.7041 5.30169 27.2616 4.741C27.8191 4.18032 28.4821 3.73567 29.212 3.43208C29.942 3.12849 30.7247 2.97182 31.5151 2.97183V3.00188Z"
            />
            <path
              d="M12.7499 16.1204C14.6435 16.1204 16.4595 15.3679 17.7985 14.0281C19.1375 12.6882 19.8899 10.8704 19.8899 8.97559C19.8859 7.08336 19.1319 5.26998 17.7933 3.93337C16.4548 2.59677 14.6409 1.84613 12.7499 1.84613C10.8588 1.84613 9.045 2.59677 7.70642 3.93337C6.36784 5.26998 5.61384 7.08336 5.60986 8.97559C5.60986 10.8704 6.36192 12.6882 7.70093 14.0281C9.03994 15.3679 10.8562 16.1204 12.7499 16.1204ZM12.7499 4.84801C13.8453 4.848 14.8959 5.28289 15.6719 6.05653C16.4478 6.83017 16.8859 7.87951 16.8899 8.97559C16.8859 10.0731 16.4485 11.1245 15.673 11.9005C14.8974 12.6765 13.8467 13.1146 12.7499 13.1186C11.6531 13.1146 10.6023 12.6765 9.82678 11.9005C9.05124 11.1245 8.61382 10.0731 8.60986 8.97559C8.61383 7.87951 9.05154 6.83017 9.82751 6.05653C10.6035 5.28289 11.6545 4.848 12.7499 4.84801Z"
            />
          </svg>
          <p class="ml-2 text-sm font-semibold">Companies</p>
        </div>

        <span class="nav-divider"></span>
        <div
          routerLink="/app/stamps"
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:text-brand-green green-hover"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 46 46"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.7 31.32H19.15C18.3729 31.32 17.6276 31.0113 17.0781 30.4618C16.5286 29.9123 16.22 29.1671 16.22 28.39V22.88C14.4664 21.7555 13.0236 20.2083 12.0241 18.3805C11.0246 16.5528 10.5006 14.5032 10.5 12.42C10.5053 9.12592 11.8169 5.96839 14.1471 3.64005C16.4773 1.3117 19.6359 0.00264373 22.93 0C26.2224 0.00528895 29.3784 1.31553 31.7064 3.64359C34.0345 5.97164 35.3447 9.12765 35.35 12.42C35.3494 14.5032 34.8254 16.5528 33.8259 18.3805C32.8264 20.2083 31.3836 21.7555 29.63 22.88V28.39C29.63 29.1671 29.3213 29.9123 28.7718 30.4618C28.2223 31.0113 27.477 31.32 26.7 31.32ZM22.93 3C20.4315 3.00264 18.036 3.99565 16.2684 5.76138C14.5008 7.52712 13.5053 9.92157 13.5 12.42C13.4991 14.1164 13.9568 15.7814 14.8246 17.239C15.6925 18.6965 16.9382 19.8924 18.43 20.7L19.22 21.13V28.39L26.7 28.32L26.63 21.13L27.42 20.7C28.9118 19.8924 30.1575 18.6965 31.0253 17.239C31.8932 15.7814 32.3509 14.1164 32.35 12.42C32.3447 9.9233 31.3505 7.53034 29.5851 5.76489C27.8196 3.99944 25.4267 3.00528 22.93 3Z"
            />
            <path
              d="M44.35 45.08H1.5C1.10218 45.08 0.720636 44.922 0.439331 44.6407C0.158026 44.3594 0 43.9778 0 43.58V38.63C0 37.3039 0.526735 36.0322 1.46442 35.0945C2.4021 34.1568 3.67392 33.63 5 33.63H40.81C42.1361 33.63 43.4078 34.1568 44.3455 35.0945C45.2832 36.0322 45.81 37.3039 45.81 38.63V43.58C45.8101 43.971 45.6576 44.3466 45.3849 44.6268C45.1122 44.907 44.7408 45.0696 44.35 45.08ZM3 42.08H42.85V38.63C42.85 38.0996 42.6392 37.5909 42.2642 37.2158C41.8891 36.8407 41.3804 36.63 40.85 36.63H5.01996C4.48953 36.63 3.98085 36.8407 3.60577 37.2158C3.2307 37.5909 3.01996 38.0996 3.01996 38.63L3 42.08Z"
            />
          </svg>
          <p class="ml-2 text-sm font-semibold">Stamps</p>
        </div>

        <span class="nav-divider"></span>
        <div
          routerLink="/app/admin-tools"
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:text-brand-green green-hover"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.25 17.3925V0.75C5.25 0.3375 4.9125 0 4.5 0C4.0875 0 3.75 0.3375 3.75 0.75V17.3925C2.88 17.7 2.25 18.525 2.25 19.5075C2.25 20.49 2.88 21.3075 3.75 21.6225V23.2575C3.75 23.67 4.0875 24.0075 4.5 24.0075C4.9125 24.0075 5.25 23.67 5.25 23.2575V21.6225C6.12 21.315 6.75 20.49 6.75 19.5075C6.75 18.525 6.12 17.7075 5.25 17.3925V17.3925Z"
            />
            <path
              d="M21.7576 19.515C21.7576 18.54 21.1276 17.715 20.2576 17.4V0.75C20.2576 0.3375 19.9201 0 19.5076 0C19.0951 0 18.7576 0.3375 18.7576 0.75V17.4C17.8876 17.7075 17.2576 18.5325 17.2576 19.515C17.2576 20.4975 17.8876 21.315 18.7576 21.63V23.2575C18.7576 23.67 19.0951 24.0075 19.5076 24.0075C19.9201 24.0075 20.2576 23.67 20.2576 23.2575V21.63C21.1276 21.3225 21.7576 20.4975 21.7576 19.515V19.515Z"
            />
            <path
              d="M12.7349 2.3775V0.75C12.7349 0.3375 12.3974 0 11.9849 0C11.5724 0 11.2349 0.3375 11.2349 0.75V2.3775C10.3649 2.685 9.73486 3.51 9.73486 4.4925C9.73486 5.475 10.3649 6.2925 11.2349 6.6075V23.25C11.2349 23.6625 11.5724 24 11.9849 24C12.3974 24 12.7349 23.6625 12.7349 23.25V6.6C13.6049 6.2925 14.2349 5.4675 14.2349 4.485C14.2349 3.5025 13.6049 2.685 12.7349 2.37V2.3775Z"
            />
          </svg>
          <p class="ml-2 text-sm font-semibold">Tools</p>
        </div>

        <span class="nav-divider"></span>
        <div
          routerLink="/app/docprep"
          routerLinkActive="text-brand-green green-icon"
          class="flex cursor-pointer hover:no-underline hover:text-brand-green green-hover"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="white"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.91 8.20503L8.75249 17.3625C8.60249 17.5125 8.41499 17.58 8.21999 17.58C8.02499 17.58 7.83749 17.505 7.68749 17.3625L4.46999 14.145C4.17749 13.8525 4.17749 13.3725 4.46999 13.08C4.76249 12.7875 5.24249 12.7875 5.53499 13.08L8.21999 15.765L16.845 7.14003C17.1375 6.84753 17.6175 6.84753 17.91 7.14003C18.2025 7.43253 18.2025 7.91253 17.91 8.20503Z"
              fill="#F5F5EB"
            />
            <path
              d="M15.705 13.8375C15.2925 13.8375 14.955 14.175 14.955 14.5875V22.5075H1.4925V1.5H9.045L9.3525 1.8075V7.11H13.68L15.2175 5.5725L9.885 0.2175C9.7425 0.075 9.555 0 9.3525 0H0.75C0.3375 0 0 0.3375 0 0.75V23.25C0 23.6625 0.3375 24 0.75 24H15.705C16.1175 24 16.455 23.6625 16.455 23.25V14.58C16.455 14.1675 16.1175 13.83 15.705 13.83V13.8375Z"
              fill="#F5F5EB"
            />
            <path
              d="M20.3775 5.41497C20.1825 5.41497 19.995 5.33997 19.845 5.19747C19.5525 4.90497 19.5525 4.43247 19.845 4.13997L22.155 1.82997C22.4475 1.53747 22.92 1.53747 23.2125 1.82997C23.505 2.12247 23.505 2.59497 23.2125 2.88747L20.9025 5.19747C20.7525 5.34747 20.565 5.41497 20.37 5.41497H20.3775Z"
              fill="#F5F5EB"
            />
            <path
              d="M23.2499 8.41504H20.9249C20.5124 8.41504 20.1749 8.07754 20.1749 7.66504C20.1749 7.25254 20.5124 6.91504 20.9249 6.91504H23.2499C23.6624 6.91504 23.9999 7.25254 23.9999 7.66504C23.9999 8.07754 23.6624 8.41504 23.2499 8.41504Z"
              fill="#F5F5EB"
            />
            <path
              d="M17.3774 4.85991C16.9649 4.85991 16.6274 4.52241 16.6274 4.10991V1.78491C16.6274 1.37241 16.9649 1.03491 17.3774 1.03491C17.7899 1.03491 18.1274 1.37241 18.1274 1.78491V4.10991C18.1274 4.52241 17.7899 4.85991 17.3774 4.85991Z"
              fill="#F5F5EB"
            />
          </svg>

          <p class="ml-2 text-sm font-semibold">DocPrep</p>
        </div>
      </div>
    </div>

    <!-- Right buttons wrapper -->
    <div class="flex self-center justify-end w-1/2 pr-2 mb-2 navbar-end">
      <button
        class="inline-flex pl-5 pr-5 capitalize bg-white rounded-full text-midnight btn btn-sm right-7"
        (click)="logout()"
      >
        <p>Logout</p>
      </button>
    </div>
  </div>
</div>
