import { NgModule } from '@angular/core';
import { environment } from '@et/environment';
import {
  v2ProfileApiModule,
  Configuration,
  ConfigurationParameters,
} from '@escrowtab/v2profile-api-client';

function profileConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.profilev2.domain,
  };
  return new Configuration(params);
}

@NgModule({
  imports: [v2ProfileApiModule.forRoot(profileConfigFactory)],
  exports: [v2ProfileApiModule],
})
export class AppApiModule {}
