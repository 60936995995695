import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';
import { DirectivesModule } from '@et/directives';

import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { I18nModule } from '@et/i18n';
import { AuthModule } from '@et/auth';
import { MSALProviders } from './add-config';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { NotificationsModule } from '@et/notifications';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarIconComponent } from './components/sidebar-icon/sidebar-icon.component';
import { ApiModule } from '@et/api';
import { environment } from '@et/environment';
import { AppApiModule } from './app-api.module';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    children: [
      {
        path: 'app',
        component: HomeComponent,
        canActivate: [MsalGuard],
        children: [
          {
            path: 'users',
            data: { title: 'Users' },
            loadChildren: () =>
              import('@et/admin/users').then((mod) => mod.AdminUsersModule),
          },
          {
            path: 'companies',
            data: { title: 'Companies' },
            loadChildren: () =>
              import('@et/admin/companies').then(
                (mod) => mod.AdminCompaniesModule,
              ),
          },
          {
            path: 'admin-tools',
            data: { title: 'Tools' },
            loadChildren: () =>
              import('@et/admin/admin-tools').then(
                (mod) => mod.AdminAdminToolsModule,
              ),
          },
          {
            path: 'stamps',
            data: { title: 'Stamps to be used for notarization via EscrowTab' },
            loadChildren: () =>
              import('@et/admin/stamps').then((mod) => mod.AdminStampsModule),
          },
          {
            path: 'docprep',
            data: { title: 'DocPrep' },
            loadChildren: () =>
              import('@et/admin/docprep').then((mod) => mod.AdminDocprepModule),
          },
        ],
      },
      {
        path: 'auth',
        loadChildren: () => import('@et/auth').then((mod) => mod.AuthModule),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('@et/error-pages').then((mod) => mod.ErrorPagesModule),
      },
      { path: '', redirectTo: 'app/users', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    SidebarComponent,
    SidebarIconComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NotificationsModule,
    AuthModule,
    I18nModule,
    DirectivesModule,
    RouterModule.forRoot(APP_ROUTES),
    AppApiModule,
    ApiModule.forRoot({
      rootUrl: `${environment.apiServer.domain}`,
    }),
  ],
  providers: [...MSALProviders],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
