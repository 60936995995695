<!-- Icon container -->
<div class="flex flex-col items-center">
  <!-- Icon image -->
  <div
    [routerLink]="routerLink"
    routerLinkActive="!bg-midnight !border"
    class="w-[70px] h-[70px] rounded-full border border-secondary flex justify-center items-center transition-colors cursor-pointer group mb-3 hover:bg-midnight hover:border-0"
  >
    <!-- SVG icon -->
    <div #svgContainer>
      <ng-content></ng-content>
    </div>
  </div>
  <!-- Icon name -->
  <span
    class="text-md text-center font-medium text-secondary-focus max-w-[98px] leading-tight"
    >{{ name }}</span
  >
</div>
