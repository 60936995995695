import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'admin-sidebar-icon',
  templateUrl: './sidebar-icon.component.html',
  styleUrls: ['./sidebar-icon.component.scss'],
})
export class SidebarIconComponent implements AfterViewInit {
  private readonly pathClasses = 'group-hover:fill-white fill-midnight';
  private readonly svgClasses = 'scale-75';

  @Input() name!: string;
  @Input() routerLink!: string;

  @ViewChild('svgContainer') svgContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit(): void {
    // Add classes to paths elements
    const pathArray =
      this.svgContainer?.nativeElement?.querySelectorAll('path');
    this.addClassesToElements(pathArray, this.pathClasses);

    // Add classes to svg element
    const svg = this.svgContainer?.nativeElement?.querySelectorAll('svg');
    this.addClassesToElements(svg, this.svgClasses);
  }

  /**
   * This function adds classes to provided HTML element
   * @param {NodeListOf<SVGSVGElement | SVGPathElement>} list - NodeListOf<SVGSVGElement | SVGPathElement>
   *
   */
  private addClassesToElements(
    list: NodeListOf<SVGSVGElement | SVGPathElement>,
    classes: string,
  ) {
    if (!list) {
      return;
    }
    list.forEach((el) => el.classList.add(...classes.split(' ')));
  }
}
