<div
  class="top-0 bottom-0 left-0 flex flex-col h-full gap-6 p-8 overflow-auto shadow-lg w-[158px]"
>
  <!-- User icon -->
  <admin-sidebar-icon name="Users" routerLink="/app/users">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        routerLinkActive="!fill-white"
        d="M39 48H9C8.60218 48 8.22039 47.8417 7.93909 47.5602C7.65778 47.2788 7.5 46.8971 7.5 46.499V31.9851C7.50397 29.4665 8.5056 27.0521 10.2854 25.2712C12.0652 23.4902 14.4777 22.488 16.9948 22.484H31.0049C33.5219 22.488 35.9348 23.4902 37.7146 25.2712C39.4944 27.0521 40.496 29.4665 40.5 31.9851V46.499C40.5 46.8971 40.3419 47.2788 40.0605 47.5602C39.7792 47.8417 39.3978 48 39 48ZM10.5 44.9981H37.5V31.9851C37.496 30.2626 36.8103 28.6123 35.5931 27.3943C34.3759 26.1764 32.7262 25.4899 31.0049 25.4859H16.9948C15.2722 25.4859 13.6201 26.1711 12.4021 27.3899C11.1841 28.6087 10.5 30.2614 10.5 31.9851V44.9981Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M24 18.0113C21.6131 18.0113 19.3238 17.0625 17.636 15.3736C15.9482 13.6847 15 11.3941 15 9.00563C15 6.61719 15.9482 4.32652 17.636 2.63764C19.3238 0.948752 21.6131 0 24 0C26.3869 0 28.6762 0.948752 30.364 2.63764C32.0518 4.32652 33 6.61719 33 9.00563C33 11.3941 32.0518 13.6847 30.364 15.3736C28.6762 17.0625 26.3869 18.0113 24 18.0113ZM24 3.00188C22.4087 3.00188 20.8823 3.63487 19.7571 4.76079C18.6319 5.88671 18 7.41334 18 9.00563C18 10.5979 18.6319 12.1253 19.7571 13.2512C20.8823 14.3771 22.4087 15.0094 24 15.0094C25.5913 15.0094 27.1173 14.3771 28.2426 13.2512C29.3678 12.1253 30 10.5979 30 9.00563C30.004 8.21469 29.8515 7.43114 29.5518 6.69926C29.252 5.96738 28.8107 5.30169 28.2532 4.741C27.6956 4.18032 27.033 3.73567 26.3031 3.43208C25.5732 3.12849 24.7905 2.97182 24 2.97183V3.00188Z"
      />
    </svg>
  </admin-sidebar-icon>

  <!-- Companies icon -->
  <admin-sidebar-icon name="Companies" routerLink="/app/companies">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        routerLinkActive="!fill-white"
        d="M38.5049 22.484H24.4797C23.8228 21.3454 22.8785 20.3994 21.7412 19.7409C20.604 19.0823 19.3139 18.7344 18 18.7317H7.5C5.51088 18.7317 3.60306 19.5229 2.19653 20.9303C0.79001 22.3377 0 24.246 0 26.2364V37.1182C0 37.5163 0.157782 37.8979 0.439087 38.1794C0.720391 38.4609 1.10218 38.6191 1.5 38.6191H15V46.4991C15 46.8971 15.1578 47.2788 15.4391 47.5603C15.7204 47.8418 16.1022 48 16.5 48H46.5C46.8978 48 47.2792 47.8418 47.5605 47.5603C47.8419 47.2788 48 46.8971 48 46.4991V31.9851C47.996 29.4665 46.9944 27.0521 45.2146 25.2712C43.4348 23.4903 41.0219 22.488 38.5049 22.484ZM3 35.6173V26.2364C3 25.0422 3.47408 23.8972 4.31799 23.0528C5.16191 22.2083 6.30653 21.7336 7.5 21.7336H18C18.5979 21.7341 19.1894 21.8541 19.7406 22.0861C20.2918 22.318 20.7913 22.6577 21.2098 23.085C19.393 23.7588 17.8256 24.9722 16.7183 26.5632C15.6109 28.1543 15.0167 30.0463 15.015 31.9851V35.6173H3ZM45 44.9981H18V31.9851C18 30.2614 18.6841 28.6087 19.9021 27.3899C21.1201 26.1711 22.7722 25.4859 24.4948 25.4859H38.4899C40.2138 25.4859 41.8676 26.1701 43.088 27.3885C44.3084 28.6069 44.996 30.2601 45 31.9851V44.9981Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M31.5151 18.0113C33.9021 18.0113 36.1913 17.0625 37.8792 15.3736C39.567 13.6847 40.5151 11.3941 40.5151 9.00563C40.5151 6.61719 39.567 4.32652 37.8792 2.63764C36.1913 0.948752 33.9021 0 31.5151 0C29.1282 0 26.839 0.948752 25.1511 2.63764C23.4633 4.32652 22.5151 6.61719 22.5151 9.00563C22.5151 11.3941 23.4633 13.6847 25.1511 15.3736C26.839 17.0625 29.1282 18.0113 31.5151 18.0113ZM31.5151 3.00188C33.1064 3.00188 34.6325 3.63487 35.7577 4.76079C36.8829 5.88671 37.5151 7.41334 37.5151 9.00563C37.5151 10.5979 36.8829 12.1253 35.7577 13.2512C34.6325 14.3771 33.1064 15.0094 31.5151 15.0094C29.9238 15.0094 28.3974 14.3771 27.2722 13.2512C26.147 12.1253 25.5151 10.5979 25.5151 9.00563C25.5112 8.21469 25.6633 7.43114 25.963 6.69926C26.2628 5.96738 26.7041 5.30169 27.2616 4.741C27.8191 4.18032 28.4821 3.73567 29.212 3.43208C29.942 3.12849 30.7247 2.97182 31.5151 2.97183V3.00188Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M12.7499 16.1204C14.6435 16.1204 16.4595 15.3679 17.7985 14.0281C19.1375 12.6882 19.8899 10.8704 19.8899 8.97559C19.8859 7.08336 19.1319 5.26998 17.7933 3.93337C16.4548 2.59677 14.6409 1.84613 12.7499 1.84613C10.8588 1.84613 9.045 2.59677 7.70642 3.93337C6.36784 5.26998 5.61384 7.08336 5.60986 8.97559C5.60986 10.8704 6.36192 12.6882 7.70093 14.0281C9.03994 15.3679 10.8562 16.1204 12.7499 16.1204ZM12.7499 4.84801C13.8453 4.848 14.8959 5.28289 15.6719 6.05653C16.4478 6.83017 16.8859 7.87951 16.8899 8.97559C16.8859 10.0731 16.4485 11.1245 15.673 11.9005C14.8974 12.6765 13.8467 13.1146 12.7499 13.1186C11.6531 13.1146 10.6023 12.6765 9.82678 11.9005C9.05124 11.1245 8.61382 10.0731 8.60986 8.97559C8.61383 7.87951 9.05154 6.83017 9.82751 6.05653C10.6035 5.28289 11.6545 4.848 12.7499 4.84801Z"
      />
    </svg>
  </admin-sidebar-icon>

  <!-- Stamps icon -->
  <admin-sidebar-icon name="Stamps" routerLink="/app/stamps">
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        routerLinkActive="!fill-white"
        d="M26.7 31.32H19.15C18.3729 31.32 17.6276 31.0113 17.0781 30.4618C16.5286 29.9123 16.22 29.1671 16.22 28.39V22.88C14.4664 21.7555 13.0236 20.2083 12.0241 18.3805C11.0246 16.5528 10.5006 14.5032 10.5 12.42C10.5053 9.12592 11.8169 5.96839 14.1471 3.64005C16.4773 1.3117 19.6359 0.00264373 22.93 0C26.2224 0.00528895 29.3784 1.31553 31.7064 3.64359C34.0345 5.97164 35.3447 9.12765 35.35 12.42C35.3494 14.5032 34.8254 16.5528 33.8259 18.3805C32.8264 20.2083 31.3836 21.7555 29.63 22.88V28.39C29.63 29.1671 29.3213 29.9123 28.7718 30.4618C28.2223 31.0113 27.477 31.32 26.7 31.32ZM22.93 3C20.4315 3.00264 18.036 3.99565 16.2684 5.76138C14.5008 7.52712 13.5053 9.92157 13.5 12.42C13.4991 14.1164 13.9568 15.7814 14.8246 17.239C15.6925 18.6965 16.9382 19.8924 18.43 20.7L19.22 21.13V28.39L26.7 28.32L26.63 21.13L27.42 20.7C28.9118 19.8924 30.1575 18.6965 31.0253 17.239C31.8932 15.7814 32.3509 14.1164 32.35 12.42C32.3447 9.9233 31.3505 7.53034 29.5851 5.76489C27.8196 3.99944 25.4267 3.00528 22.93 3Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M44.35 45.08H1.5C1.10218 45.08 0.720636 44.922 0.439331 44.6407C0.158026 44.3594 0 43.9778 0 43.58V38.63C0 37.3039 0.526735 36.0322 1.46442 35.0945C2.4021 34.1568 3.67392 33.63 5 33.63H40.81C42.1361 33.63 43.4078 34.1568 44.3455 35.0945C45.2832 36.0322 45.81 37.3039 45.81 38.63V43.58C45.8101 43.971 45.6576 44.3466 45.3849 44.6268C45.1122 44.907 44.7408 45.0696 44.35 45.08ZM3 42.08H42.85V38.63C42.85 38.0996 42.6392 37.5909 42.2642 37.2158C41.8891 36.8407 41.3804 36.63 40.85 36.63H5.01996C4.48953 36.63 3.98085 36.8407 3.60577 37.2158C3.2307 37.5909 3.01996 38.0996 3.01996 38.63L3 42.08Z"
      />
    </svg>
  </admin-sidebar-icon>

  <!-- eNotes Preference icon -->
  <!-- <admin-sidebar-icon
    name="eNotes Preference"
    routerLink="/app/enotes-preference"
  >
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_559_337)">
        <path
          d="M44.5491 13.155L31.8029 0.435083C31.6624 0.296061 31.4958 0.186 31.3127 0.111353C31.1296 0.0367063 30.9335 -0.00111626 30.7357 2.5081e-05H13.435C13.0363 2.5081e-05 12.654 0.158174 12.3721 0.439478C12.0902 0.720782 11.9319 1.1022 11.9319 1.50002V16.5C11.9319 16.8978 12.0902 17.2793 12.3721 17.5606C12.654 17.8419 13.0363 18 13.435 18C13.8336 18 14.216 17.8419 14.4978 17.5606C14.7797 17.2793 14.9381 16.8978 14.9381 16.5V3.00002H30.1194L30.7357 3.61526V14.22H41.3626L41.9939 14.8352V45H13.435C13.0363 45 12.654 45.1582 12.3721 45.4395C12.0902 45.7208 11.9319 46.1022 11.9319 46.5C11.9319 46.8978 12.0902 47.2792 12.3721 47.5606C12.654 47.8419 13.0363 48 13.435 48H43.497C43.8956 48 44.2779 47.8419 44.5598 47.5606C44.8417 47.2792 45.0001 46.8978 45.0001 46.5V14.22C44.9992 14.0215 44.959 13.8249 44.8816 13.6421C44.8041 13.4593 44.6912 13.294 44.5491 13.155Z"
        />
        <path
          d="M18.1848 36.6753L19.6879 39.5998C19.8139 39.8495 20.0074 40.0591 20.2464 40.2048C20.4854 40.3506 20.7605 40.4272 21.0406 40.4253C21.2718 40.4296 21.5 40.3721 21.702 40.2598C22.0587 40.0826 22.3302 39.7717 22.4571 39.3948C22.5839 39.0179 22.5556 38.6061 22.3784 38.25L14.8629 23.25L14.7577 23.1152C14.701 23.0287 14.6355 22.9481 14.5623 22.875C14.4926 22.8022 14.4113 22.7412 14.3218 22.6948C14.2842 22.6471 14.2384 22.6064 14.1865 22.5747H14.0963L13.7957 22.4854H13.5251H13.2396C13.1418 22.5063 13.0461 22.5358 12.954 22.5747H12.8488C12.8488 22.5747 12.7586 22.6648 12.7135 22.6948C12.6239 22.7412 12.5427 22.8022 12.473 22.875C12.3997 22.9481 12.3343 23.0287 12.2776 23.1152L12.1724 23.25L4.65687 38.25C4.47967 38.6061 4.45137 39.0179 4.5782 39.3948C4.70503 39.7717 4.97661 40.0826 5.33326 40.2598C5.53522 40.3721 5.76343 40.4296 5.99463 40.4253C6.27475 40.4272 6.54985 40.3506 6.78888 40.2048C7.02791 40.0591 7.22135 39.8495 7.34741 39.5998L8.85051 36.6753H18.1848ZM10.2634 33.6753L13.4801 27.2849L16.6817 33.6753H10.2634Z"
        />
        <path d="M28.4509 40.425L37.4695 31.425L28.4509 22.425V40.425Z" />
      </g>
      <defs>
        <clipPath id="clip0_559_337">
          <rect width="48" height="48" />
        </clipPath>
      </defs>
    </svg>
  </admin-sidebar-icon> -->

  <!-- Tools icon -->
  <admin-sidebar-icon name="Tools" routerLink="/app/admin-tools">
    <svg
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="gray"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        routerLinkActive="!fill-white"
        d="M5.25 17.3925V0.75C5.25 0.3375 4.9125 0 4.5 0C4.0875 0 3.75 0.3375 3.75 0.75V17.3925C2.88 17.7 2.25 18.525 2.25 19.5075C2.25 20.49 2.88 21.3075 3.75 21.6225V23.2575C3.75 23.67 4.0875 24.0075 4.5 24.0075C4.9125 24.0075 5.25 23.67 5.25 23.2575V21.6225C6.12 21.315 6.75 20.49 6.75 19.5075C6.75 18.525 6.12 17.7075 5.25 17.3925V17.3925Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M21.7576 19.515C21.7576 18.54 21.1276 17.715 20.2576 17.4V0.75C20.2576 0.3375 19.9201 0 19.5076 0C19.0951 0 18.7576 0.3375 18.7576 0.75V17.4C17.8876 17.7075 17.2576 18.5325 17.2576 19.515C17.2576 20.4975 17.8876 21.315 18.7576 21.63V23.2575C18.7576 23.67 19.0951 24.0075 19.5076 24.0075C19.9201 24.0075 20.2576 23.67 20.2576 23.2575V21.63C21.1276 21.3225 21.7576 20.4975 21.7576 19.515V19.515Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M12.7349 2.3775V0.75C12.7349 0.3375 12.3974 0 11.9849 0C11.5724 0 11.2349 0.3375 11.2349 0.75V2.3775C10.3649 2.685 9.73486 3.51 9.73486 4.4925C9.73486 5.475 10.3649 6.2925 11.2349 6.6075V23.25C11.2349 23.6625 11.5724 24 11.9849 24C12.3974 24 12.7349 23.6625 12.7349 23.25V6.6C13.6049 6.2925 14.2349 5.4675 14.2349 4.485C14.2349 3.5025 13.6049 2.685 12.7349 2.37V2.3775Z"
      />
    </svg>
  </admin-sidebar-icon>

  <!-- DocPrep icon -->
  <admin-sidebar-icon name="DocPrep" routerLink="/app/docprep">
    <svg
      class="ml-2"
      width="47"
      height="48"
      viewBox="0 0 47 48"
      fill="grey"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        routerLinkActive="!fill-white"
        d="M38.0877 20.4379L26.8574 9.19747V2.88182C26.8574 1.2953 25.5683 0 23.9794 0H2.87808C1.29362 0 0 1.29095 0 2.88182V36.4769C0 38.0635 1.28929 39.3588 2.87808 39.3588H9.40686C10.0667 39.7195 10.7743 40.0021 11.5122 40.202V45.1181C11.5122 46.7045 12.8015 48 14.3903 48H35.4918C37.076 48 38.3699 46.7088 38.3699 45.1181V20.7204L38.0877 20.4379ZM26.8574 11.9141L35.0922 20.1553H27.8171C27.2872 20.1553 26.8574 19.725 26.8574 19.1948V11.9141ZM1.91872 36.4769V2.88182C1.91872 2.35153 2.34847 1.92121 2.87808 1.92121H23.9794C24.5091 1.92121 24.9389 2.35153 24.9389 2.88182V8.64112H14.3859C12.8015 8.64112 11.5079 9.93205 11.5079 11.5229V21.3594C7.36663 22.4982 4.31493 26.2841 4.31493 30.7829C4.31493 33.3561 5.32204 35.6946 6.94987 37.4419H2.87375C2.34414 37.4419 1.91439 37.0116 1.91439 36.4813L1.91872 36.4769ZM12.6669 38.5025C12.6669 38.5025 12.6626 38.5025 12.6582 38.5025C9.01182 37.8244 6.23798 34.6209 6.23798 30.7785C6.23798 26.4449 9.7628 22.9155 14.0951 22.9155C18.4274 22.9155 21.9522 26.4406 21.9522 30.7785C21.9522 35.1164 18.4274 38.6416 14.0951 38.6416C13.6089 38.6416 13.1314 38.5894 12.6669 38.5068V38.5025ZM35.4918 46.0743H14.3903C13.8607 46.0743 13.4309 45.6439 13.4309 45.1138V40.5237C13.6523 40.5367 13.8694 40.5584 14.0951 40.5584C19.4866 40.5584 23.8709 36.1683 23.8709 30.7785C23.8709 25.3887 19.4866 20.9986 14.0951 20.9986C13.8694 20.9986 13.6523 21.016 13.4309 21.0334V11.5186C13.4309 10.9883 13.8607 10.558 14.3903 10.558H24.9433V19.1991C24.9433 20.7856 26.2323 22.0809 27.8214 22.0809H36.4553V45.1181C36.4553 45.6482 36.0255 46.0786 35.4961 46.0786L35.4918 46.0743Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M31.8107 5.97664C32.3401 5.97664 32.77 5.54633 32.77 5.01602V0.960607C32.77 0.430317 32.3401 0 31.8107 0C31.2809 0 30.8511 0.430317 30.8511 0.960607V5.01602C30.8511 5.54633 31.2809 5.97664 31.8107 5.97664Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M46.0186 14.1483H41.964C41.4346 14.1483 41.0048 14.5786 41.0048 15.1088C41.0048 15.6391 41.4346 16.0695 41.964 16.0695H46.0186C46.548 16.0695 46.9779 15.6391 46.9779 15.1088C46.9779 14.5786 46.548 14.1483 46.0186 14.1483Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M37.5578 10.3315C37.8054 10.3315 38.0482 10.2359 38.2352 10.049L44.4122 3.86373C44.7857 3.48992 44.7857 2.88139 44.4122 2.50758C44.0391 2.13377 43.4313 2.13377 43.0577 2.50758L36.8808 8.69286C36.5072 9.06665 36.5072 9.67519 36.8808 10.049C37.0673 10.2359 37.3149 10.3315 37.5578 10.3315Z"
      />
      <path
        routerLinkActive="!fill-white"
        d="M13.0229 35.2596C13.0533 35.2379 13.0837 35.2161 13.114 35.1857L19.2912 29.0005C19.6645 28.6266 19.6645 28.0181 19.2912 27.6443C18.9179 27.2705 18.3102 27.2705 17.9368 27.6443L12.4368 33.1515L10.249 30.9608C9.87569 30.587 9.26795 30.587 8.8946 30.9608C8.5213 31.3346 8.5213 31.9431 8.8946 32.3169L11.7597 35.1857C11.8378 35.2596 11.9246 35.3161 12.0158 35.3639C12.1504 35.4291 12.2936 35.4682 12.4412 35.4682C12.6018 35.4682 12.7624 35.4248 12.91 35.3422C12.9274 35.3291 12.9491 35.3248 12.9708 35.3118C12.9925 35.2987 13.0099 35.277 13.0315 35.264L13.0229 35.2596Z"
      />
    </svg>
  </admin-sidebar-icon>
</div>
