<div class="flex flex-col h-screen overflow-x-hidden bg-sand">
  <admin-navbar></admin-navbar>
  <h1 class="mx-12 mt-4 mb-1 text-2xl font-bold">{{ pageTitle }}</h1>
  <div class="flex flex-col flex-grow mx-12 mt-2">
    <div class="flex flex-col flex-grow w-full h-full bg-white 4xl:mx-none">
      <!-- <admin-sidebar></admin-sidebar> -->
      <div class="w-full max-w[calc(100% - 200px)] p-4 bg-base-300 h-full">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
<admin-footer></admin-footer>
